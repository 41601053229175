<template>
  <div class="date-formatter">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span
          v-if="!withSlot"
          v-on="date && tooltip && (showDateTooltips || fromIntranet) ? on : ''"
          >{{ dateFormatted }}</span
        >
        <div
          v-else
          v-on="date && tooltip && (showDateTooltips || fromIntranet) ? on : ''"
        >
          <slot name="content"></slot>
        </div>
      </template>
      <span>{{ timeZone ? timeZone : ownerTimeZone.timeZone }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import DateUtils from "@/flit2go-web-core/util/paginationUtil";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

const fromIntranet = process.env.VUE_APP_TITLE == "Intranet";
export default {
  name: "date-formatter",
  props: {
    date: { type: [String, Number], required: false },
    timeZone: { type: String, required: false },
    formatterFormat: {
      type: String,
      default: "DD/MM/YYYY HH:mm:ss",
      required: false,
    },
    withSlot: { type: Boolean, default: false },
    tooltip: { type: Boolean, default: true },
  },
  data() {
    return {
      DateUtils,
      moment,
      fromIntranet: false,
    };
  },
  inject: { ownerTimeZone: { default: { timeZone: "Europe/Madrid" } } },
  computed: {
    ...mapGetters({
      showDateTooltips: "settings/getShowDateTooltips",
      user: "auth/user",
    }),
    dateFormatted() {
      let resp = "";
      const timeZone = this.getTimeZone();
      if (this.date && timeZone) {
        resp = moment(this.date)
          .tz(timeZone)
          .locale(this.$i18n.locale.substring(0, 2))
          .format(this.formatterFormat);
      } else {
        resp = this.$t("common.noInformation");
      }

      return resp;
    },
  },
  mounted() {
    this.fromIntranet = fromIntranet;
  },
  methods: {
    getTimeZone() {
      let timeZone = "";
      if (this.user) {
        timeZone = this.user.timeZone;
      } else if (this.timeZone) {
        timeZone = this.timeZone;
      } else {
        timeZone = this.ownerTimeZone.timeZone;
      }
      return timeZone;
    },
  },
};
</script>
